.dropzone-wrapper {
  .dropzone-text {
    display: flex;
    height: 200px;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    background: white;
    border: 1px solid var(--primary);
  }
}

.dropzone-current-files {
  margin-top: 1rem;

  .current-file-item {
    .current-file-item-delete {
      font-size: 12px;
      cursor: pointer;
    }
  }
}
