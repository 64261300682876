
$statusList: (
  draft: 'draft',
  to_validate: 'to-validate',
  to_approve: 'to-approve',
  refused: 'refused',
  validated: 'validated',
);

.infobox {
  & > div {
    margin-top: 21px;
    margin-bottom: 21px;
  }

  
  .innerBox {
    background-color: #fcfcfc;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 80px;
    margin: 24px 0;
  }
  .status-sticker {
    margin-right: 26px;
    margin-bottom: 1.2rem;
    width: 12px;
    height: 12px;
    padding: 0;
    background-color: #ddd;
    border-radius: 50%;
  }
}

.items-bordered > div {
  padding-left: 22px;
  border-left: #d7d8df 1px solid; /* border for all by default */

  &:first-child,
  &.no-border {
    border-left: none;
  }
}

.additionalInfo {
  padding-left: 22px;
}

.popover-deadlines {
  .react-date-picker {
    width: 100%;
  }
}

.deadlines-action {
  button {
    width: 50px;
  }
}

.detail-block3 {
  .dl-attachment {
    font-size: 1.4rem;
    cursor: pointer;
  }

  .timeline-item {
    &.timeline-start {
      .timeline-badge {
        background-color: var(--pink);
      }
    }
    &.timeline-info {
      .timeline-badge {
        background-color: var(--gray);
      }
    }

    &.timeline-end {
      .timeline-badge {
        background-color: var(--white);
        border: 3px solid var(--pink);
      }
    }

    &.timeline-processing-duration {
      .timeline-badge {
        background-color: var(--processing-duration);
      }
    }

    &.timeline-comment {
      position: relative;

      .timeline-badge {
        background-color: var(--comment);
      }
    }

    .toggle {
      position: relative;
      top: -2px;
      float: right;
      cursor: pointer;
    }

    &.timeline-attachment {
      .timeline-badge {
        background-color: var(--gray-dark);
      }
    }
  }

  .entries {
    .timeline-comment:hover {
      background-color: var(--comment-background);
    }
  }

  .comment {
    border-left: var(--comment) 1px solid;
    color: var(--comment);
    margin-left: 190px;
    margin-bottom: 21px;
    padding: 2px 0;
  }
}
