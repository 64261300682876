:root {
  --toulouse-blue1: #187280;
  --toulouse-blue2: #00A4B6;
  --toulouse-blue3: #86D1D7;
  --toulouse-blue4: #030F40;
  --toulouse-blue5: #D6F1FB;
  --toulouse-blue6: #3D466C;
  --toulouse-pink: #E6007E;
  --toulouse-orange: #EA560D;
  --toulouse-grey1: #D5D6DE;
  --toulouse-grey2: #767C97;
  --toulouse-grey3: #F5F8FE;
  --toulouse-grey4: #6C757D;
  --toulouse-grey5: #F4F8FF;
  --toulouse-grey6: #E9ECEF;
  --toulouse-grey7: #F8F9FA;

  --primary: var(--toulouse-blue4);
  --secondary: var(--toulouse-blue1);
  --light-gradient: var(--toulouse-blue1);
  --light-green: var(--toulouse-blue3);

  /* Status */
  --status-to-validate: #DEB861;
  --status-to-approve: #FF8363;
  --status-refused: var(--red);
  --status-validated: var(--green);
  --status-draft: #030F40;

  /* Status Timeline */
  --status-timeline-to-validate:  #FF8363;
  --status-timeline-to-approve: #FF8363;
  --status-timeline-refused: var(--red);
  --status-timeline-validated: var(--green);
  --status-timeline-draft: #030F40;


  /* Deadlines */
  --deadlines-limit-deadlines: #FF8363;
  --deadlines-late-deadlines: var(--red);
  --deadlines-ontime-deadlines: var(--green);

  --processing-duration: #ccc;
  --comment: #030f40;
  --comment-background: var(--toulouse-grey7);

  --request-icon-text: #FFF;
  --request-icon-border: #FFF;
  --request-icon-bg-intervention: #000;

  /* Search */
  --filter-tag-border: var(--toulouse-grey1);
  --filter-tag-bg-hover: var(--toulouse-grey1);

  --filter-search-submit: var(--toulouse-grey1);

  --input-text-border: var(--toulouse-grey1);

  --btn-text: var(--white);
  --btn-text-bg: var(--toulouse-blue2);
  --btn-outline-border: var(--toulouse-blue2);
  --btn-outline-hover: var(--toulouse-blue2);

  --nav-item-bg-active: var(--toulouse-blue2);
  --nav-item-text-active: var(--white);

  --detail-text-light: var(--toulouse-grey2);

  --body-background: var(--toulouse-grey3);

  --add-request-box-color: var(--white);
  --option-control-btn-hover-color: var(--toulouse-blue2);

  /* Filters indicators */
  --indicator-background: var(--white);
  --indicator-background-disabled: var(--toulouse-grey1);
  --indicator-count: var(--toulouse-blue4);

  --map-legend-title-text-color: var(--toulouse-grey4);
  --map-control-icon-color: var(--white);
  --map-control-text: var(--white);
  --map-control-bg: rgba(3, 15, 64, 0.3);
  --map-control-layers-bg: rgba(3, 15, 64, 0.6);
  --map-popup-header-background: var(--toulouse-grey5);
  --map-popup-header-text: var(--toulouse-blue6);
  --map-popup-light-text: var(--toulouse-grey2);
  --map-popup-label: var(--toulouse-grey2);
  --map-popup-text: var(--toulouse-blue4);
  --map-popup-link: var(--toulouse-blue6);

  --table-list-header-bg: var(--toulouse-blue5);

  --loading-spinner-color:var(--toulouse-blue1);
  --comments-tab-title :var(--toulouse-blue4);
  --comments-tab-border-color:var(--toulouse-grey6);
  --comments-tab-background-active:var(--white);

  --request-detail-address:var(--toulouse-grey4);
  --request-list-result-text: var(--toulouse-grey4);

  /* Nav Urgent Request */
  --nav-urgent-request-bg: var(--toulouse-grey7);
  --nav-urgent-request-header-bg: var(--toulouse-blue2);
  --nav-urgent-request-header-color: var(--white);
  --nav-urgent-request-text-color: var(--toulouse-blue4);
  --nav-urgent-request-label-color: var(--toulouse-grey4);

  /* Notification Setting */
  --notification-text-secondary-color: var(--toulouse-grey4);
  --notification-box-header-background-color: var(--toulouse-blue3);
  --notification-box-border-color: var(--toulouse-grey1);
  --notification-succes-alert-background-color: var(--status-done);
  --notification-success-alert-color: #FFFFFF;

}
