.list-reporting {
  justify-content: flex-end;

  .status-indicator {
    height: 48px;
    padding: 12px !important;
    margin-right: 1rem;

    strong {
      color: var(--indicator-count);
    }

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: var(--indicator-background-disabled);
    }
  }

  .status-sticker {
    position: relative;
    top: -3px;
    margin-right: 3px;
    display: inline-block;
  }
}
