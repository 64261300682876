// @import "zindex";
@import "colors";
// @import "mixins";
@import "sz";
@import "forms";

html {
    font-size: 62.5%;
}

body {
    font-size: 1.4rem;
    background: var(--body-background);
}

html,
body,
#root {
    height: 100%;
}

#root {
    padding-top: 60px;
}

h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.5em;
}
.small {
    font-size: 1rem;
}

.h-title {
    height: calc(100% - 10.5rem);
}

.box-shadow {
    background: var(--white);
    box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05), 0 2px 4px 0 rgba(17, 95, 251, 0.1);
    padding: 8px 16px;
}

.ptn-5 {
    top: -5px;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

$statusList: (
    draft: "draft",
    to-approve: "to-approve",
    to-validate: "to-validate",
    validated: "validated",
    refused: "refused"
);
$deadLinesList: (
    late-deadlines: "late-deadlines",
    limit-deadlines: "limit-deadlines",
    ontime-deadlines: "ontime-deadlines",
);
.sz-table tbody tr {
    td:first-child::after {
        opacity: 1;
    }

    @each $key,
    $value in $statusList {
        &.request-row-#{$value} {
            position: relative;
            td:first-child {
                // background: var(--status-#{$value});
                border-left: 5px solid var(--status-#{$value});
            }
        }
    }
    @each $key,
    $value in $deadLinesList {
        .request-row-#{$value} {
            position: relative;
            border-left: 5px solid var(--deadlines-#{$value});
            
        }
    }
}
.detail-block3 {
    .timeline-item {
        @each $key,
        $value in $statusList {
            &.timeline-#{$value} {
                .timeline-badge {
                    background-color: var(--status-#{$value});
                }
            }
        }
    }
}

$statusList: (
    draft: "draft",
    to_approve: "to-approve",
    to_validate: "to-validate",
    validated: "validated",
    refused: "refused"
);

.status-sticker {
    width: 12px;
    height: 12px;
    padding: 0;
    background-color: #DDD;
    border-radius: 50%;
    display: inline-block;
    vertical-align: sub;
    margin: 4px;

    @each $key,
    $value in $statusList {
        &.status-sticker-#{$key} {
            background-color: var(--status-#{$value});
        }
    }
}

abbr[title] {
    text-decoration: none;
}

.position-absolute-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.no-scroll {
    overflow-y: hidden;
}

.hover-underline:hover {
    text-decoration: underline;
}

.title-tooltip {
    font-size: 1.2rem;
}


.no-gutters {
    .value-presentation {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.value-presentation {
    $greyColor: var(--gray-500);

    .label {
        display: block;
        font-weight: 500;
        font-size: 1.4rem;
        color: var(--primary-dark);
    }

    .value {
        display: block;
        font-weight: 500;
        color: $greyColor;
    }

    &.inversed {
        .label {
            font-size: 1.2rem;
            color: $greyColor;
        }

        .value {
            font-size: 1.4rem;
            color: var(--primary-dark);
        }
    }

    &:not(:last-child) {
        border-right: 2px solid $greyColor;
    }
}

.bg-gray {
    background-color: var(--gray-100);
}


.modal {
    z-index: 99999;
}

.modal-content {
    .modal-title {
        text-align: center;
    }
}

.toast-wrapper {
    z-index: 100000;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 15%;
}
