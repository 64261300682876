@import "src/mixins";
@import "src/zindex";

.main-wrapper {
  height: 100%;
  overflow-y: hidden;

  main {
    @extend .main-wrapper;
  }
}

.main-overlay {
  content: '';
  position: absolute;
  z-index: map-get($zIndex, mainOverlay);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.sz-aside {
  nav {
    position: relative;
    height: 100%;
  }

  &__item {
    &.active {
      @include background(var(--nav-item-bg-active));
      @include color(var(--nav-item-text-active));
    }
  }

  &.sz-aside-exit-done {
    width: 74px;

    .sz-aside {
      &__item {
        padding: 24px;
      }
    }
  }
}
