.indicator-wrapper {
  .back {
      font-size: 1.2rem;
      font-weight: bold;
  }

  .indicator-header-title {

  }

  .infobox {
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 32px;
    margin-bottom: 24px;
  }
  
  .status-sticker {
    position: relative;
    top: -2px;
    margin-right: 5px;
  }

  .btn.btn-download {
    height: 30px;
  }

  .button-status {
    display: flex;
    margin-top: -6.5rem;
    margin-bottom: 2rem;
    min-height: 30px;
  }

  .sub-title {
    color: var(--gray-600);
    font-weight: bold;
  }
}

.indicator-comments-list {
  padding-top: 1rem;
  border-top: 1px solid var(--gray-200);
}

.comment-item {
  border-bottom: 1px solid var(--gray-200);
  color: var(--gray-600);
  padding: 1rem 0;
}

.report-wrapper {
  .current-file-item {
    text-align: center;
  }
}

.button-add-attachment-wrapper {
  position: absolute;
  top: -21px;
  right: 0;

  button {
    height: 40px;
  }
}

.to-approve-modal-value, .to-approve-modal-comment, .to-approve-modal-file .dropzone-text {
  border: 2px solid var(--input-text-border);
}