.sheet-list-table {
  table-layout: fixed !important;

  tr td {
    text-overflow: ellipsis;
    word-break: unset;
    overflow: hidden;
  }

  tr th:nth-child(1), tr td:nth-child(1) {
    width: 300px;
  }

  tr th:nth-child(2), tr td:nth-child(2) {
    width: 400px;
  }

  tr th:last-child, tr td:last-child {
    width: 140px;
    i {
      margin-left: 5px;
    }
  }
}